<template>
  <div class="viewDashBoard project-route-index">
    <div class="container">
      <transition
        name="fade"
        mode="out-in"
        @beforeLeave="beforeLeave"
        @enter="enter"
        @afterEnter="afterEnter"
      >
        <div key="1" v-if="loader" class="white-row loader-route">
          <div class="lds-sub-ripple">
            <div></div>
            <div></div>
          </div>
        </div>
        <div v-else key="2" class="white-row">
          <div class="show-project-portal-listing">
            <!-- // Filter Area -->
            <div class="general-filters">
              <div class="col-sm-12">
                <div class="row">
                  <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12">
                    <div class="row top-gen-heading">
                      <h1 class="global-style-heading">
                        lead
                        <a
                          @click.prevent="refreshBrandTable"
                          href=""
                          class="refreshData"
                          ><i class="fas fa-redo-alt"></i
                        ></a>
                      </h1>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="horizontal-row">
              <hr />
            </div>
            <section class="brands">
              <transition
                name="fade"
                mode="out-in"
                @beforeLeave="beforeLeave"
                @enter="enter"
                @afterEnter="afterEnter"
              >
                <div key="1" v-if="data_loader" class="white-row loader-route">
                  <div class="lds-sub-ripple">
                    <div></div>
                    <div></div>
                  </div>
                </div>
                <div v-else class="brand-wrapper">
                  <div
                    class="row"
                    v-if="
                      typeof lead_data !== 'undefined' &&
                        Object.keys(lead_data).length
                    "
                  >
                    <div
                      class="col-xl-12 col-lg-12 col-md-12 col-sm-12"
                    >
                        <div
                          :class="lead_data.data.leadstatus.color_code"
                        >
                          <div class="row">
                              
                              <div class="col-sm-5">
                                  <div class="left-box">
                                      <div class="usr-role-name-glb text-right">
                                          <div class="custom-avtr orv-3">
                                          <avatar
                                              :inline="true"
                                              :username="lead_data.data.user"
                                          ></avatar>
                                          </div>
                                      </div>
                                      <p class="eclipse-para">
                                          {{ lead_data.data.user }}
                                      </p>
                                      <div class="status-dropbox" 
                                      v-if="
                                          typeof lead_data.data.leadstatus !== 'undefined' &&
                                            Object.keys(lead_data.data.leadstatus).length
                                        ">
                                          <div class="dropdown show">
                                              <a class="btn btn-danger btn-sm dropdown-toggle" href="#" role="button" id="dropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                  <template v-if="lead_data.data.leadstatus.icon_class">
                                                      <span v-if="lead_data.data.icon_status">
                                                        <i :class="lead_data.data.leadstatus.icon_class"></i>
                                                      </span>
                                                      <div
                                                        v-else
                                                        class="form_submit_loader"
                                                      >
                                                        <div class="lds-ellipsis">
                                                          <div></div>
                                                          <div></div>
                                                          <div></div>
                                                          <div></div>
                                                        </div>
                                                      </div>
                                                  </template>
                                                  <template v-else>
                                                    {{ lead_data.data.leadstatus.name }}
                                                  </template>
                                              </a>
                                              <div class="dropdown-menu" aria-labelledby="dropdownMenuLink">
                                                  <a v-for="(status , status_index) in lead_status.data" :key="status_index" @click.prevent="changeStatus(status_index)"  class="dropdown-item" :class="(lead_data.data.leadstatus.id == status.id ? 'active' : '' )" href="#">
                                                    <i :class="status.icon_class"></i> {{ status.name }}
                                                  </a>
                                              </div>
                                          </div>
                                      </div>
                                      <div class="steps-dropbox"
                                        v-if="
                                        typeof lead_steps.data !== 'undefined' &&
                                          Object.keys(lead_steps.data).length
                                      ">
                                          <div class="dropdown show">
                                              <a class="btn btn-danger btn-sm dropdown-toggle" href="#" role="button" id="dropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                  {{ lead_data.data.leadstep.name }}
                                              </a>

                                              <div class="dropdown-menu" aria-labelledby="dropdownMenuLink">
                                                  <a v-for="(steps , steps_index) in lead_steps.data" :key="steps_index" @click.prevent="changeSteps(steps_index)"  class="dropdown-item" :class="(lead_data.data.leadstep.id == steps.id ? 'active' : '' )" href="#">
                                                    {{ steps.name }}
                                                  </a>
                                              </div>
                                          </div>
                                      </div>
                                      <div class="amount-dops">
                                          ${{ lead_data.data.amount }}
                                      </div>
                                      <div class="dates">
                                          <p>Created : {{ lead_data.data.created }}</p>
                                          <p>Updated : {{ lead_data.data.updated }}</p>
                                      </div>
                                      <div class="lead-history"
                                      v-if="
                                        typeof lead_data.data.activity !== 'undefined' &&
                                          Object.keys(lead_data.data.activity).length
                                      ">
                                        <h6>
                                          <i class="fas fa-history"></i> 
                                          <span>since creation by {{ lead_data.data.user }} (Jan 10 06:44 PM)</span>
                                        </h6>
                                        <ul class="list-unstyled">
                                          <li class="text-small"
                                            v-for="(activity,act_index) in lead_data.data.activity" :key="act_index"
                                          >
                                            <div class="m-0">

                                              <div class="text-muted">{{ activity.created }}: </div>
                                              <div class="ovh pl-1">
                                               {{ activity.activity_message }}
                                              </div>
                                            </div>
                                          </li>

                                        </ul>
                                      </div>
                                  </div>
                              </div>


                              <div class="col-sm-7">
                                  <div class="wrap-box">
                                      <div class="header-top">
                                          {{ lead_data.data.title }}
                                          <dropdown>
                                              <template slot="icon"
                                              ><i class="fas fa-ellipsis-v"></i
                                              ></template>
                                              <template slot="body">
                                              <ul class="list-group">
                                                  <li class="list-group-item">
                                                  <router-link
                                                      :to="{
                                                      path: '/leads/edit/' + lead_data.data.id
                                                      }"
                                                      tag="a"
                                                  >
                                                      Edit
                                                  </router-link>
                                                  </li>
                                              </ul>
                                              </template>
                                          </dropdown>

                                      </div>
                                      <div class="box">
                                      <div class="project-details">
                                          <div class="project-listing">
                                              <p class="eclipse-para">
                                                  Name : {{ lead_data.data.name }}
                                              </p>
                                              <p class="eclipse-para">
                                                  Email : {{ lead_data.data.email }}
                                              </p>
                                              <p class="eclipse-para">
                                                  Contact : {{ lead_data.data.contact }}
                                              </p>
                                              <p class="eclipse-para">
                                                  Zip Code : {{ lead_data.data.zip_code }}
                                              </p>
                                              <p class="eclipse-para">
                                                  Package : {{ lead_data.data.package }}
                                              </p>
                                              <hr>
                                              <h5>Tags</h5>
                                              <h6>Brand : <span class="badge badge-dark">{{ lead_data.data.brand }}</span></h6>
                                              <h6 v-if="
                                                  typeof lead_data.data.account_manager !== 'undefined' &&
                                                    Object.keys(lead_data.data.account_manager).length
                                                ">Sales Person : 
                                                <span class="badge badge-dark" v-for="(am,am_index) in lead_data.data.account_manager" :key="am_index">
                                                  {{ am.name }}
                                                </span>
                                              </h6>
                                              <h6 v-if="
                                                  typeof lead_data.data.lead_source !== 'undefined' &&
                                                    Object.keys(lead_data.data.lead_source).length
                                                ">Lead Source : 
                                                <span class="badge badge-dark m-10" v-for="(lead_sour,lead_sor_index) in lead_data.data.lead_source" :key="lead_sor_index">
                                                  {{ lead_sour.name }}
                                                </span>
                                              </h6>
                                              <div class="comments"
                                                v-if="
                                                  typeof lead_data.data.comments !== 'undefined' &&
                                                    Object.keys(lead_data.data.comments).length
                                              ">
                                                <hr>
                                                <h5>Comments</h5>
                                                <ul class="list-group list-group-flush">
                                                  <li
                                                    class="list-group-item"
                                                    v-for="(comment, comment_index) in lead_data.data.comments"
                                                    :key="comment_index"
                                                    :class="[ (comment.current_user == comment.user.id) ? 'text-left icon-left' : 'text-right']"
                                                  >
                                                    <div
                                                      class="media">
                                                      <avatar
                                                        class="mr-3"
                                                        :username="comment.user.name"
                                                      ></avatar>
                                                      <div class="media-body">
                                                        <p>{{ comment.comment }}</p>
                                                      </div>
                                                    </div>
                                                  </li>
                                                </ul>
                                              </div>
                                          </div>
                                      </div>
                                      </div>
                                      <div class="header-footer">
                                        <form class="comment-form" v-on:submit.prevent="onSubmit($event)">
                                          <div class="form-group">
                                              <input name="comment_input" type="text" class="form-control" placeholder="Enter a comment here">
                                            </div>
                                        </form>
                                      </div>
                                  </div>
                              </div>
                          </div>
                        </div>
                    </div>
                  </div>
                  <div v-else class="row">
                    <div class="col-sm-12">
                      No Data Found.
                    </div>
                  </div>
                </div>
              </transition>
            </section>
          </div>
        </div>
      </transition>
    </div>
  </div>
</template>

<script>
import Avatar from "vue-avatar";
import Dropdown from "bp-vuejs-dropdown";
export default {
  components: {
    Avatar,
    Dropdown
  },
  computed: {
    getActiveUserData() {
      return this.$store.state.auth;
    }
  },
  data() {
    return {
      loader: true,
      data_loader: true,
      formData: {
        search: ""
      },
      lead_data: {},
      lead_status : {},
      lead_steps : {},
    };
  },
  mounted() {
    document.title = this.$router.app._route.meta.pageTitle;
    this.loader = false;
    this.getILeadData();
    this.getILeadStatus();
    this.getILeadSteps();
  },
  methods: {
    async getILeadActivityData() {
      const $this = this;
      const data = $this.$store.dispatch("getHttp", {
        url:
          "leads/activies/"+this.$route.params.lead_id +
          "?timezone=" +
          Intl.DateTimeFormat().resolvedOptions().timeZone
      });
      await data.then(function(response) {
        if (typeof response !== "undefined") {
          $this.lead_data.data.activity = response.data.data;
        }
      });
    },
    async changeStatus(new_index) {
      const $this = this;
      $this.$store.dispatch("generalLoader", {
        response: true
      });
      let current_id = $this.lead_status.data[new_index].id;
      let lead = $this.lead_data.data;
      if(current_id === lead.leadstatus.id) {
        $this.$store.dispatch("generalLoader", {
          response: false
        });
        return;
      }
      this.$set(this.lead_data.data, 'icon_status', false);
      const data = $this.$store.dispatch("postHttp", {
        url: "leads/change-lead-status",
        formDetails: { 
          lead_id : lead.id,
          status_id : current_id,
        },
        formEvent: null
      });
      await data.then( (response) => {
        if (typeof response !== "undefined") {
          // this.getILeadData();
          this.$set(this.lead_data.data, 'leadstatus', $this.lead_status.data[new_index]);
          $this.$store.dispatch("displayMessageErrors", {
            response: response,
            formEvent: null,
          });
          $this.$store.dispatch("generalLoader", {
            response: false
          });
          this.$set(this.lead_data.data, 'icon_status', true);
          this.getILeadActivityData();
        }
      });
    },
    async changeSteps(new_index) {
      const $this = this;
      $this.$store.dispatch("generalLoader", {
        response: true
      });
      let current_id = $this.lead_steps.data[new_index].id;
      let lead = $this.lead_data.data;
      if(current_id === lead.leadstep.id) {
        $this.$store.dispatch("generalLoader", {
          response: false
        });
        return;
      }
      const data = $this.$store.dispatch("postHttp", {
        url: "leads/change-lead-step",
        formDetails: { 
          lead_id : lead.id,
          step_id : current_id,
        },
        formEvent: null
      });
      await data.then(function(response) {
        if (typeof response !== "undefined") {
          $this.lead_data.data.leadstep.name = $this.lead_steps.data[new_index].name;
          $this.lead_data.data.leadstep.id = $this.lead_steps.data[new_index].id;
          $this.$store.dispatch("displayMessageErrors", {
            response: response,
            formEvent: null,
          });
          $this.$store.dispatch("generalLoader", {
            response: false
          });
          $this.getILeadActivityData();
        }
      });
    },
    refreshBrandTable() {
      this.getILeadData();
      this.getILeadStatus();
      this.getILeadSteps(); 
    },
    async getILeadSteps() {
      const $this = this;
      const data = $this.$store.dispatch("getHttp", {
        url:
          "leads/lead-steps"
      });
      await data.then(function(response) {
        if (typeof response !== "undefined") {
          $this.lead_steps = response.data;
        }
      });
    },
    async getILeadStatus() {
      const $this = this;
      const data = $this.$store.dispatch("getHttp", {
        url:
          "leads/lead-status"
      });
      await data.then(function(response) {
        if (typeof response !== "undefined") {
          $this.lead_status = response.data;
        }
      });
    },
    async getILeadData() {
      const $this = this;
      $this.data_loader = true;
      const data = $this.$store.dispatch("getHttp", {
        url:
          "leads/show/" +
          this.$route.params.lead_id + 
          "?timezone=" +
          Intl.DateTimeFormat().resolvedOptions().timeZone
      });
      await data.then(function(response) {
        if (typeof response !== "undefined") {
          $this.lead_data = response.data;
          $this.data_loader = false;
        }
      });
    },
    beforeLeave(element) {
      this.prevHeight = getComputedStyle(element).height;
    },
    enter(element) {
      const { height } = getComputedStyle(element);

      element.style.height = this.prevHeight;

      setTimeout(() => {
        element.style.height = height;
      });
    },
    afterEnter(element) {
      element.style.height = "auto";
    },
    async onSubmit(event,lead_index) {
      this.$store.dispatch("generalLoader", {
        response: true
      });
      let comment = event.target.elements.comment_input.value;
      let lead_id = this.lead_data.data.id;
      if((comment == 'null') || (comment == '')) {
        this.$store.dispatch("generalLoader", {
        response: false
        });
        return;
      }
      const $this = this;

      const data = $this.$store.dispatch("postHttp", {
        url: "comment/store",
        formDetails: {
          'lead_id' : lead_id,
          'comment' : comment,
        },
        formEvent: null
      });
      await data.then( (response) => {
        if (typeof response !== "undefined") {
          this.lead_data.data = response.data.data;
          this.$store.dispatch("generalLoader", {
            response: false
          });
          $this.$store.dispatch("showtostrMessageNotifications", {
            message: 'Comment Posted Successfully!.',
            type: 'success'
          });
          event.target.elements.comment_input.value = '';
          this.getILeadActivityData();
        }
      });
    },
  }
};
</script>
<style lang="scss" scoped>
.m-10 {
  margin-right:10px;
}
.left-box {
    position: relative;
    padding-left: 40px;
    .usr-role-name-glb.text-right {
        left: 25px;
        right: unset;
        top: 0;
    }
    p.eclipse-para {
        padding-bottom: 10px;
    }
    .status-dropbox {
        margin-bottom: 10px;
        .lds-ellipsis {
          top: -3px;
        }
        .dropdown-toggle {
          &::after {
              content: unset;
          }
        }
        .dropdown-item {
            padding: 7px 13px;
            i {
              margin-right:5px;
            }
            svg {
              margin-right:5px;
            }
        }
        .btn {
            width: auto;
            background: #1190EE;
            border-color: #1190EE;
            font-size: 15px;
            padding: 0 15px;
        }
    }
    .steps-dropbox {
        margin-bottom: 10px;
        .btn {
            width: auto;
            background: #1190EE;
            border-color: #1190EE;
            font-size: 15px;
            padding: 0 15px;
        }
    }
    .lead-history {
        padding: 20px 20px 0;
        height: 200px;
        overflow: hidden;
        overflow-y: scroll;
        box-shadow: 0 0 2px #00000029;
        margin-top: 20px;
        background: #faf9f7 0% 0% no-repeat padding-box;
        h6 {
          font-size: 14px;
          position:relative;
          padding-left:25px;
          span {

          }
          svg {
            margin-right: 5px;
            position: absolute;
            left: 0;
            top: 5px;
          } 
          i {
            margin-right: 5px;
            position: absolute;
            left: 0;
            top: 5px;
          }
        }
        ul.list-unstyled {
          li {
            border-bottom: 1px solid rgba(0, 0, 0, 0.1);
            padding: 12px 0;
          }
        }
    }
}
body .white-row.loader-route {
  top: 0;
}
span.bp-dropdown__btn.bp-dropdown__btn--active.-bp__btn--active {
    background-color: transparent;
}
.fade-enter-active,
.fade-leave-active {
  transition-duration: 0.3s;
  transition-property: opacity;
  transition-property: height, opacity;
  transition-timing-function: ease;
  overflow: hidden;
}
.project-route-index {
  .show-project-portal-listing {
    .general-filters {
      .form-group {
        width: 95%;
        .input-group {
          input {
            border-radius: 3px !important;
            background: #f9f9f9;
            letter-spacing: 0.28px;
            font-size: 14px;
            padding: 3px 15px;
            color: #364460;
            border: none;
            font-weight: 200;
            height: auto;
          }
          button {
            background: #f9f9f9;
            color: #3b4c54;
            border: none;
            padding: 4px 8px;
          }
        }
      }
      .form-actions {
        width: 100%;
        button {
          width: 100%;
          margin-left: 0;
          padding: 5px 0;
        }
      }
    }
    h4 {
      letter-spacing: 0.28px;
      color: #323f54;
      font-size: 14px;
      font-weight: bold;
      margin-bottom: 0;
      padding-bottom: 7px;
    }
    p {
      margin: 0;
      letter-spacing: 0.28px;
      color: #2a1c0b;
    }
    section {
      border-bottom: 1px solid #ebebeb;
      padding: 30px 0;
      &.brands {
        padding-bottom: 0px;
        padding-top: 0;
        h4 {
          padding: 5px 0 25px;
        }
        .wrap-box {
          background: #faf9f7 0% 0% no-repeat padding-box;
          border-radius: 3px;
          padding: 0;
          min-height: auto;
          margin-bottom: 30px;
          .header-top {
                background: #1190EE;
                color: #fff;
                position: relative;
                padding: 10px 20px;
                .bp-dropdown {
                    position: absolute;
                    right: 19px;
                    top: 9px;
                }
            // position: absolute;
            // right: 26px;
            // z-index: 100;
            // top: 19px;
            .bp-dropdown__btn {
              border: none;
            }
            .bp-dropdown__body {
              padding: 0;
              background-color: #fff;
              box-shadow: 0 5px 15px -5px rgba(0, 0, 0, 0.5);
              z-index: 9999;
              border-radius: 4px;
              border: none;
              margin-top: 10px;
              .list-group {
                .list-group-item {
                  border: none;
                  position: relative;
                  display: block;
                  border-bottom: 1px solid rgba(0, 0, 0, 0.125);
                  padding: 10px 20px 10px 7px;
                  text-align: left;
                  a {
                    color: #212529;
                  }
                }
              }
            }
          }
          .box {
            position: relative;
            .project-details {
              p {
                &.project-id {
                  background: #ece9df 0% 0% no-repeat padding-box;
                  border-radius: 3px;
                  display: inline-block;
                  letter-spacing: 0.24px;
                  color: #323f54;
                  font-size: 12px;
                  padding: 4px 10px;
                }
                &.project-status {
                  letter-spacing: 0.24px;
                  color: #323f54;
                  font-size: 12px;
                  padding: 3px 0 15px;
                  font-weight: 400;
                }
              }
              .project-listing {
                span.badge.badge-dark {
                    margin-right: 5px;
                }
                padding: 12px 20px;
                p {
                  margin: 0;
                  letter-spacing: 0.28px;
                  color: #323f54;
                  font-size: 14px;
                  font-weight: 500;
                  text-transform: inherit;
                  line-height: 25px;
                  font-family: "Noto Sans TC", sans-serif;
                }
              }
            }
            .footer-box {
              display: block;
              position: relative;
              width: 100%;
              padding: 20px 0 10px;
              border-top: 1px solid rgba(0, 0, 0, 0.1);
              .usr-role {
                p {
                  letter-spacing: 0.24px;
                  color: #918a82;
                  font-weight: 300;
                  font-size: 12px;
                }
              }
            }
          }
        }
      }
    }
  }
}
.comments {
  ul {
    li {
      position : relative;
      text-align: right;
      padding: 15px 50px;
      .vue-avatar--wrapper.mr-3 {
          position: absolute;
          right: 10px;
          left: unset;
          margin-right: 0 !important;
          top : 12px;
      }
      &.icon-left {
        .vue-avatar--wrapper.mr-3 {
          position: absolute;
          right: unset;
          left: 10px;
          margin-right: 0 !important;
          top : 12px;
      }
      }
    }
  }
}
.danger {
  .status-dropbox {
    .btn {
      background: #dc3545;
      border-color: #dc3545;
    }
    .dropdown-item.active, .dropdown-item:active {
      background: #dc3545;
    }
  }
}
.success {
  .status-dropbox {
    .btn {
      background: #28a745;
      border-color: #28a745;
    }
    .dropdown-item.active, .dropdown-item:active {
      background: #28a745;
    }
  }
}
</style>
