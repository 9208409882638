import { render, staticRenderFns } from "./admin-lead-show.vue?vue&type=template&id=4099ff92&scoped=true&"
import script from "./admin-lead-show.vue?vue&type=script&lang=js&"
export * from "./admin-lead-show.vue?vue&type=script&lang=js&"
import style0 from "./admin-lead-show.vue?vue&type=style&index=0&id=4099ff92&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4099ff92",
  null
  
)

export default component.exports